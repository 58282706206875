import { notification } from "ant-design-vue";
import axios from "axios";
import { ComponentPublicInstance } from "vue";

export function errorHandler(error: Error, vm: ComponentPublicInstance): void {
    console.log("herre", error, vm, axios.isAxiosError(error));
    if (!axios.isAxiosError(error)) {
        throw error;
    }

    if (error.response && error.response.status == 401) {
        notification.error({
            message: "Authentication expired",
            description:
                "Your authentication token for this page has expired. Please refresh the page.",
        });
    } else if (error.response && error.response.status == 403) {
        notification.error({
            message: "Forbidden",
            description:
                "You do not have permission to access this page. If you believe this is an error, please contact the administrator.",
        });
    } else if (
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.data.errors
    ) {
        notification.error({
            message: error.response.data.title,
            description: Object.entries(error.response.data.data)
                .map((error) => `Error for field ${error}: ${error}`)
                .join(),
        });
    } else if (
        error.response &&
        (error.response.status === 400 ||
            error.response.status === 409 ||
            error.response.status === 503)
    ) {
        notification.error({
            message: error.response.data.title,
            description: error.response.data.detail,
        });
    } else if (error.response && error.response.status === 500) {
        notification.error({
            message: "Internal Server Error",
            description:
                "An unexpected error occurred, our team has been notified",
        });
    }
}
